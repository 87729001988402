<template>
  <v-menu transition="slide-y-transition" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-col
        class="colorSwatches"
        v-bind="attrs"
        v-on="on"
        :style="`background: ${selectedColor};`">
      </v-col>
    </template>

    <v-list>
      <v-list-item link>
        <v-container>
          <v-row v-for="(rows, index) in colorSwatches" :key="index">
            <v-col
              v-for="color in rows"
              class="colorSwatches-mini"
              :style="`background: ${color};`" :key="color"
              @click="onSelectColor(color)">
            </v-col>
          </v-row>
        </v-container>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { colorSwatches } from '@/utils/tempUtils'
import Events from '@/core/service/events'

export default {
  name: '',
  props: {
    initialColor: {
      type: String,
      default: '#00FF00'
    }
  },
  directives: {},
  components: {},
  data: () => ({
    selectedColor: null
  }),
  created () {},
  mounted () {
    const self = this

    self.selectedColor = self.initialColor
    Events.$on('customColorSwatcher::init', data => { self.selectedColor = data.initialColor })
  },
  computed: {
    colorSwatches: colorSwatches
  },
  watch: {},
  methods: {
    onSelectColor (color) {
      const self = this
      self.selectedColor = color
      this.$emit('onClick', { color })
    }
  },
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  .colorSwatches {
    height: 55px;
    width: 55px;
    border-radius: 512px;

    &-mini {
      height: 24px;
      width: 24px;
      margin: 4px;
      border-radius: 512px;
    }
  }
</style>